import Anggapati1 from "./anggapati (1).webp";
import Anggapati2 from "./anggapati (2).webp";
import Anggapati3 from "./anggapati (3).webp";
import Anggapati4 from "./anggapati (4).webp";
import Anggapati5 from "./anggapati (5).webp";
import Anggapati6 from "./anggapati (6).webp";
import Anggapati7 from "./anggapati (7).webp";

const anggapati = [
  Anggapati1,
  Anggapati2,
  Anggapati3,
  Anggapati4,
  Anggapati5,
  Anggapati6,
  Anggapati7,
];

export default anggapati;
