import Surroundingarea1 from "./surroundingarea (1).webp";
import Surroundingarea2 from "./surroundingarea (2).webp";
import Surroundingarea3 from "./surroundingarea (3).webp";
import Surroundingarea4 from "./surroundingarea (4).webp";
import Surroundingarea5 from "./surroundingarea (5).webp";
import Surroundingarea6 from "./surroundingarea (6).webp";
import Surroundingarea8 from "./surroundingarea (8).webp";
import Surroundingarea9 from "./surroundingarea (9).webp";
import Surroundingarea10 from "./surroundingarea (10).webp";
import Surroundingarea11 from "./surroundingarea (11).webp";
import Surroundingarea12 from "./surroundingarea (12).webp";
import Surroundingarea13 from "./surroundingarea (13).webp";
import Surroundingarea14 from "./surroundingarea (14).webp";

const surroundingarea = [
  Surroundingarea1,
  Surroundingarea2,
  Surroundingarea3,
  Surroundingarea4,
  Surroundingarea5,
  Surroundingarea6,
  Surroundingarea8,
  Surroundingarea9,
  Surroundingarea10,
  Surroundingarea11,
  Surroundingarea12,
  Surroundingarea13,
  Surroundingarea14,
];

export default surroundingarea;
