import React from "react";
import "./fasilitas.scss";
import clubhouse from "../../asset/fasilitas/Al-Irsyad Satya Islamic School.webp";
import joggingtrack from "../../asset/fasilitas/Bumi Pancasona Sport Club.webp";
import civiccenter from "../../asset/fasilitas/Masjid Al-Irsyad Satya.webp";
import breeze from "../../asset/fasilitas/Parahyangan Golf.webp";
import qbig from "../../asset/fasilitas/Rumah Sakit Ibu & Anak Parahyangan.webp";
import eka from "../../asset/fasilitas/lapangan basket.webp";
import hospital from "../../asset/fasilitas/lapangan futsal.webp";
import hotel from "../../asset/fasilitas/Damri.webp";
import shop from "../../asset/fasilitas/Trans Metro Pasundan.webp";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const fasilitas = () => {
  const slides = [
    { src: clubhouse, des: "Al-Irsyad Satya Islamic School" },
    { src: joggingtrack, des: "Bumi Pancasona Sport Club" },
    { src: civiccenter, des: "Masjid Al-Irsyad Satya" },
    { src: breeze, des: "Parahyangan Golf" },
    { src: qbig, des: "RSIA Parahyangan" },
    { src: eka, des: "Lapangan Basket" },
    { src: shop, des: "Trans Metro Pasundan" },
    { src: hotel, des: "Damri" },
    { src: hospital, des: "Lapangan Futsal" },
  ];
  const settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    lazyLoad: true,
    speed: 500,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="surroundingcontainer" id="fasilitas">
      <div className="title">Fasilitas Cluster</div>
      <div className="content">
        {slides.map((slide, index) => (
          <div className="center" key={index}>
            <img className="imgcontent" src={slide.src} alt="Surrounding" />
            <div className="imgdes">
              <span>{slide.des}</span>
            </div>
          </div>
        ))}
      </div>
      <div className="content1">
        <Slider {...settings}>
          {slides.map((slide, index) => (
            <div className="center" key={index}>
              <img className="imgcontent" src={slide.src} alt="Surrounding" />
              <div className="imgdes">
                <span>{slide.des}</span>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default fasilitas;
