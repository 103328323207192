import "./Hero.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const Hero = () => {
  const waOpen = () => {
    window.open(
      "https://api.whatsapp.com/send?phone=6285795865769&text=Halo%20Ines%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20(https://marketing-kotabaruparahyangan.com/)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0",
      "_blank"
    );
  };
  return (
    <section id="hero" className="section-hero">
      <div className="container-hero">
        <h3 className="hero-title">
          WELCOME TO <br />
          KOTA BARU PARAHYANGAN
        </h3>
        <button onClick={waOpen} className="btn-navbar">
          <FontAwesomeIcon icon={faWhatsapp} />
          &nbsp; Contact Us
        </button>
      </div>
    </section>
  );
};

export default Hero;
