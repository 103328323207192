import React from "react";
import surroundingarea from "../../../asset/surrounding/surroundingarea";
import "./surrounding.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartShopping,
  faHospital,
  faLocationCrosshairs,
  faSchool,
} from "@fortawesome/free-solid-svg-icons";

const surrounding = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className="surrounding" id="surrounding">
      <div className="container-surrounding">
        <div className="heading-surrounding">Surrounding Area</div>
        <div className="carousel">
          <Slider {...settings}>
            {surroundingarea.map((image, index) => (
              <div className="parent">
                <img
                  className="image-surrounding"
                  key={index}
                  src={image}
                  alt="foto surrounding"
                />
              </div>
            ))}
          </Slider>
        </div>
        <div className="containerlist-surrounding">
          <div className="list-surrounding">
            <div className="list">
              <div className="heading-list">
                <div className="pink"></div>
                <span>
                  <FontAwesomeIcon icon={faSchool} size="1x" />
                </span>
                &nbsp;&nbsp;&nbsp;Education
              </div>
              <div className="listed">
                <span>
                  <FontAwesomeIcon icon={faLocationCrosshairs} /> &nbsp;
                  Al-Irsyad Satya Islamic School
                </span>
                <span>
                  <FontAwesomeIcon icon={faLocationCrosshairs} /> &nbsp; BPK
                  Penabur School
                </span>
                <span>
                  <FontAwesomeIcon icon={faLocationCrosshairs} /> &nbsp;
                  Universitas Kristen Maranatha
                </span>
                <span>
                  <FontAwesomeIcon icon={faLocationCrosshairs} /> &nbsp; Cahaya
                  Bangsa Classical School{" "}
                </span>
              </div>
            </div>
            <div className="list">
              <div className="heading-list">
                <div className="pink"></div>
                <span>
                  <FontAwesomeIcon icon={faHospital} />
                </span>
                &nbsp;&nbsp;&nbsp;Health Care
              </div>
              <div className="listed">
                <div className="a">
                  <FontAwesomeIcon icon={faLocationCrosshairs} /> &nbsp; Cahya
                  Kawaluyan Hospital
                </div>
                <div className="a">
                  <FontAwesomeIcon icon={faLocationCrosshairs} /> &nbsp; RSIA
                  Parahyangan
                </div>
                <div className="a">
                  <FontAwesomeIcon icon={faLocationCrosshairs} /> &nbsp; Zen
                  Reflexiology
                </div>
                <div className="a">
                  <FontAwesomeIcon icon={faLocationCrosshairs} /> &nbsp; Rumah
                  Sakit TK II Dustira
                </div>
              </div>
            </div>
            <div className="list">
              <div className="heading-list">
                <div className="pink"></div>
                <span>
                  <FontAwesomeIcon icon={faCartShopping} />
                </span>
                &nbsp;&nbsp;&nbsp;Entertainment
              </div>
              <div className="listed">
                <div className="a">
                  <FontAwesomeIcon icon={faLocationCrosshairs} /> &nbsp;
                  Parahyangan Golf
                </div>
                <div className="a">
                  <FontAwesomeIcon icon={faLocationCrosshairs} /> &nbsp; Lake
                  View
                </div>
                <div className="a">
                  <FontAwesomeIcon icon={faLocationCrosshairs} /> &nbsp; Wahoo
                  Waterland
                </div>
                <div className="a">
                  <FontAwesomeIcon icon={faLocationCrosshairs} /> &nbsp; Kota
                  Baru Food Market
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default surrounding;
