import Paramawati1 from "./Paramawati (1).webp";
import Paramawati2 from "./Paramawati (2).webp";
import Paramawati3 from "./Paramawati (3).webp";
import Paramawati4 from "./Paramawati (4).webp";
import Paramawati5 from "./Paramawati (5).webp";
import Paramawati6 from "./Paramawati (6).webp";
import Paramawati7 from "./Paramawati (7).webp";
import Paramawati8 from "./Paramawati (8).webp";
import Paramawati9 from "./Paramawati (9).webp";
import Paramawati10 from "./Paramawati (10).webp";
import Paramawati11 from "./Paramawati (11).webp";
import Paramawati12 from "./Paramawati (12).webp";

const paramawati = [
  Paramawati1,
  Paramawati2,
  Paramawati3,
  Paramawati4,
  Paramawati5,
  Paramawati6,
  Paramawati7,
  Paramawati8,
  Paramawati9,
  Paramawati10,
  Paramawati11,
  Paramawati12,
];

export default paramawati;
