import React, { useState } from "react";
import logo from "./logof.png";
import "./footer.scss";
import { NavHashLink } from "react-router-hash-link";
import map from "./mapkbp.jpeg";

const Footer = () => {
  const [isMenuOpen] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className="footer">
      <div className="footer-atas">
        <div className="logofooter">
          <img src={logo} alt="logo" />
          <div className="h1">Marketing Gallery</div>
          <div className="h2">Kota Baru Parahyangan</div>
          <div className="h2">+6285795865769</div>
        </div>
        <div className="ftr">
          <div className="navigasifooter">
            <div className="judul">Peta Situs</div>
            <ul className={isMenuOpen ? "nav-menu active" : "nav-menu"}>
              <li className="nav-item">
                <NavHashLink smooth to="#promo" className="nav-links">
                  Promo
                </NavHashLink>
              </li>
              <li className="nav-item">
                <NavHashLink smooth to="#rumah" className="nav-links">
                  Our Home
                </NavHashLink>
              </li>
              <li className="nav-item">
                <NavHashLink smooth to="#surrounding" className="nav-links">
                  Surrounding Area
                </NavHashLink>
              </li>
              <li className="nav-item" onClick={scrollToTop}>
                <NavHashLink smooth to="/" className="nav-links">
                  Back to Top
                </NavHashLink>
              </li>
            </ul>
          </div>
          <div className="imageMap">
            <div className="judulimage" id="lokasi">
              Lokasi
            </div>
            <img src={map} alt="mapimage" />
          </div>
        </div>
      </div>
      <div className="footer-bawah">
        © 2024 Kota Baru Parahyangan. All Rights Reserved.
      </div>
    </div>
  );
};

export default Footer;
