import Nilakencana1 from "./nilakencana (1).webp";
import Nilakencana2 from "./nilakencana (2).webp";
import Nilakencana3 from "./nilakencana (3).webp";
import Nilakencana4 from "./nilakencana (4).webp";
import Nilakencana5 from "./nilakencana (5).webp";

const nilakencana = [
  Nilakencana1,
  Nilakencana2,
  Nilakencana3,
  Nilakencana4,
  Nilakencana5,
];

export default nilakencana;
