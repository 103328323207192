import React from "react";
import Cardw from "../../../component/Card/Cardw";
import datap from "../../../component/Card/data.json";
import asih from "../../../asset/wisesaasih/asih";
import asri from "../../../asset/wisesaasri/asri";
import "./newlaunch.scss";

const imageMap = {
  asih,
  asri,
};

const newlaunch = () => {
  return (
    <div className="newlaunc" id="newlaunch">
      <div id="montana" className="container-produkmtr">
        <div className="header">
          <div className="h2">New Launching Bumi Luhur</div>
        </div>
        <div className="montana">
          {" "}
          {datap.wisesa.map((card, index) => {
            // Determine the logo dynamically

            return (
              <Cardw
                key={index}
                carousel={imageMap[card.imagesKey]}
                cardtitle={card.cardtitle} // Dynamically import logo images
                title={card.title}
                details={card.details}
                harga={card.harga}
                whatsappLink={card.whatsappLink}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default newlaunch;
