import React from "react";
import imgPromo from "../../../asset/promo.png";
import "./promo.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const promo = () => {
  const waOpen = () => {
    window.open(
      "https://api.whatsapp.com/send?phone=6285795865769&text=Halo%20Ines%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Promo%20Kota%20Baru%20Parahyangan)%20(https://marketing-kotabaruparahyangan.com/)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0",
      "_blank"
    );
  };
  return (
    <div className="promo" id="promo">
      <div className="container-promo">
        <div className="gambarpromo">
          <img src={imgPromo} alt="foto-promo" />
        </div>
        <div className="contentpromo">
          <div className="headingpromo">
            Promo Keseluruhan Kota Baru Parahyangan
          </div>
          <div className="listpromo">
            <ul className="list">
              <li>
                <FontAwesomeIcon icon={faCheckCircle} color="#004672" />
                &nbsp;&nbsp;Soft DP&nbsp;
              </li>
              <li>
                <FontAwesomeIcon icon={faCheckCircle} color="#004672" />
                &nbsp;&nbsp;Free Smartdoor Lock&nbsp;
              </li>
              <li>
                <FontAwesomeIcon icon={faCheckCircle} color="#004672" />
                &nbsp;&nbsp;Free Smarthome System&nbsp;
              </li>
              <li>
                <FontAwesomeIcon icon={faCheckCircle} color="#004672" />
                &nbsp;&nbsp;Free Canopy&nbsp;
              </li>
              <li>
                <FontAwesomeIcon icon={faCheckCircle} color="#004672" />
                &nbsp;&nbsp;Free Logam Mulia up to 17gr&nbsp;
              </li>
              <li>
                <FontAwesomeIcon icon={faCheckCircle} color="#004672" />
                &nbsp;&nbsp;Discount Biaya Provisi 45%&nbsp;
              </li>
            </ul>
          </div>
          <div className="disclaimer">T&C Apply*</div>
          <button onClick={waOpen} className="btn-navbar">
            <FontAwesomeIcon icon={faWhatsapp} /> &nbsp; Learn More
          </button>
        </div>
      </div>
    </div>
  );
};

export default promo;
