import React from "react";
import imageAbout from "../../../asset/about.webp";
import "./about.scss";

const about = () => {
  const waOpen = () => {
    window.open(
      "https://api.whatsapp.com/send?phone=6285795865769&text=Halo%20Ines%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20(https://marketing-kotabaruparahyangan.com/)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0",
      "_blank"
    );
  };
  return (
    <div className="about" id="about">
      <div className="container-about">
        <div className="aboutkanan">
          <img src={imageAbout} alt="nilapadmi" />
        </div>
        <div className="aboutkiri">
          <div className="heading">An Aesthetic Living</div>
          <div className="desk">
            Perumahan Kota Bumi Parahyangan, salah satu proyek perumahan
            terpadu, terletak di lokasi strategis menghadap ke arah barat daya
            Bandung, perumahan ini menawarkan pemandangan alam yang memukau,
            terutama matahari terbenam yang menambah nilai estetika dan
            kenyamanan tinggal.
            <br />
            Mengutamakan pengelolaan lingkungan yang berkelanjutan dengan
            mengintegrasikan teknologi ramah lingkungan, pengelolaan air, dan
            penghijauan yang memadai.
          </div>
          <button onClick={waOpen} className="btn-navbar">
            &nbsp; Learn More
          </button>
        </div>
      </div>
    </div>
  );
};

export default about;
