import Nilapadmi1 from "./Nilapadmi (1).webp";
import Nilapadmi2 from "./Nilapadmi (2).webp";
import Nilapadmi3 from "./Nilapadmi (3).webp";
import Nilapadmi4 from "./Nilapadmi (4).webp";
import Nilapadmi5 from "./Nilapadmi (5).webp";
import Nilapadmi6 from "./Nilapadmi (6).webp";
import Nilapadmi7 from "./Nilapadmi (7).webp";
import Nilapadmi8 from "./Nilapadmi (8).webp";
import Nilapadmi9 from "./Nilapadmi (9).webp";
import Nilapadmi10 from "./Nilapadmi (10).webp";
import Nilapadmi11 from "./Nilapadmi (11).webp";
import Nilapadmi12 from "./Nilapadmi (12).webp";
import Nilapadmi13 from "./Nilapadmi (13).webp";
import Nilapadmi14 from "./Nilapadmi (14).webp";
import Nilapadmi15 from "./Nilapadmi (15).webp";
import Nilapadmi16 from "./Nilapadmi (16).webp";
import Nilapadmi17 from "./Nilapadmi (17).webp";

const nilapadmi = [
  Nilapadmi1,
  Nilapadmi2,
  Nilapadmi3,
  Nilapadmi4,
  Nilapadmi5,
  Nilapadmi6,
  Nilapadmi7,
  Nilapadmi8,
  Nilapadmi9,
  Nilapadmi10,
  Nilapadmi11,
  Nilapadmi12,
  Nilapadmi13,
  Nilapadmi14,
  Nilapadmi15,
  Nilapadmi16,
  Nilapadmi17,
];

export default nilapadmi;
