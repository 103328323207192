import React from "react";
import "./produk.scss";
import Cardc from "../../../component/Card/Cardc";
import datap from "../../../component/Card/data.json";
import nilakencana from "../../../asset/nilakencana/nilakencana";
import nilapadmi from "../../../asset/nilapadmi/nilapadmi";
import simakirana from "../../../asset/simakirana/simakirana";
import paramawati from "../../../asset/paramawati/paramawati";
import anggapati from "../../../asset/anggapati/anggapati";
import tarubhawana from "../../../asset/tarubhawana/tarubhawana";
const imageMap = {
  nilakencana,
  nilapadmi,
  simakirana,
  paramawati,
  anggapati,
  tarubhawana,
};

const produk = () => {
  return (
    <div className="produk" id="rumah">
      <div id="montana" className="container-produk">
        <div className="header">
          <div className="h2">Our Home</div>
        </div>
        <div className="montana">
          {" "}
          {datap.produkr.map((card, index) => {
            // Determine the logo dynamically

            return (
              <Cardc
                key={index}
                carousel={imageMap[card.imagesKey]}
                cardtitle={card.cardtitle} // Dynamically import logo images
                title={card.title}
                details={card.details}
                harga={card.harga}
                whatsappLink={card.whatsappLink}
                id={card.id}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default produk;
