import "./Navbar.scss";
import { useState } from "react";
import logo from "../../asset/logo 1.png";
import { HashLink, NavHashLink } from "react-router-hash-link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const waOpen = () => {
    window.open(
      "https://api.whatsapp.com/send?phone=6285795865769&text=Halo%20Ines%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20(https://marketing-kotabaruparahyangan.com/)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0",
      "_blank"
    );
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <HashLink smooth to="/" className="navbar-logo">
          <img src={logo} alt="Logo" />
        </HashLink>
        <ul className={isMenuOpen ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <NavHashLink smooth to="/" className="nav-links">
              Beranda
            </NavHashLink>
          </li>
          <li className="nav-item">
            <NavHashLink smooth to="#promo" className="nav-links">
              Promo
            </NavHashLink>
          </li>
          <li className="nav-item">
            <NavHashLink smooth to="#rumah" className="nav-links">
              Cluster
            </NavHashLink>
          </li>
          <li className="nav-item">
            <NavHashLink smooth to="#lokasi" className="nav-links">
              Lokasi
            </NavHashLink>
          </li>
        </ul>
        <div className="menu-icon" onClick={toggleMenu}>
          <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} />
        </div>
        <button onClick={waOpen} className="btn-navbar">
          <FontAwesomeIcon icon={faWhatsapp} />
          &nbsp; Contact Us
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
